<template>
	<b-container fluid v-if="$checkPermission('list-template')" id="attachmentPage">
		<cg-modal :data="newAttachmentFields" @update="confirmUploadAttachment"></cg-modal>

		<cg-loader :display="loading"></cg-loader>

		<cg-localized-alert :alertVariant="alertVariant" ref="cgLocAlert" :alertMessage="alertMessage"></cg-localized-alert>

		<b-modal id="copyAttachmentModal" content-class="shadow" size='md' buttonSize='md' okVariant='danger'
				bodyClass="text-center" hide-footer :centered='true' :title="$t('Attachment.CopyAttachment')">

			<b-form-group label-cols-lg="12" :label="$t('Attachment.CopyAttachmentLabel')" label-size="lg" label-class="label-profile pt-0">
				<b-row id="copyAttachmentCompanySelect" class="mt-2">
					<b-col class="col-sm-9">
						<b-form-select id="copyAttachmentCompanySelect" v-model="attachmentCompany" :options="companyOptions"></b-form-select>
					</b-col>
					<b-col class="col-sm-3 pl-0">
						<b-button variant="primary" id="copyAttachmentButton" class="btn-action w-100" @click="copyTemplateAttachment">{{ $t('General.Copy') }}</b-button>
					</b-col>
				</b-row>
			</b-form-group>
		</b-modal>
			
		<div class="px-4 mb-4">
			<b-card-title class="mb-4">{{ $t('Navbar.Attachment') }}</b-card-title>

			<!-- Attachment Option -->
			<b-row align-h="end" class="mb-4">
				<b-col md="12" lg="8" align-self="end">
					
					<!-- New Attachment button -->
					<b-row align-h="end">
						<b-col cols="auto">
							<b-button @click="createNewAttachment()" size="sm" variant="primary" class="mb-3">
								<font-awesome-icon class="vertical-align-middle mr-2" :icon="['fas', 'plus']"/>{{ $t('Attachment.NewAttachment') }}
							</b-button>
						</b-col>
					</b-row>

					<!-- Attachment Filter options -->
					<b-form-group :label="$t('General.Show')" label-cols-sm="3" label-cols-md="4" label-cols-xl="5" label-align-sm="right" label-size="sm" class="mt-1"
							label-for="attachmentScopeFilter">
						<b-form-select class="mr-2" id="attachmentScopeFilter" size="sm" v-model="attachmentScopeFilter" :options="attachmentScopeOptions" @change="getAttachments"></b-form-select>
					</b-form-group>

				</b-col>
			</b-row>
		
			<b-table-simple
				id="attachmentListTable"
				ref="attachmentTable"
				sort-by.sync="id"
				sort-desc.sync="desc"
				:empty-text="$t('General.TableEmpty')"
				responsive="true" hover striped show-empty bordered>
				<b-thead>
					<b-tr>
						<b-th>{{ $t('Attachment.ID') }}</b-th>
						<b-th class="text-center">{{ $t('General.Copy') }}</b-th>
						<b-th>{{ $t('Attachment.Description') }}</b-th>
						<b-th>{{ $t('Attachment.Language') }}</b-th>
						<b-th>{{ $t('Template.Company') }}</b-th>
						<b-th>{{ $t('General.Actions') }}</b-th>
					</b-tr>
				</b-thead>
				<b-tbody v-for="(attachment, index) in attachmentList" :key="index">
					<b-tr>
						<b-td :rowspan="attachment.data.length+2">{{attachment.id}}</b-td>
					</b-tr>
					<b-tr>
						<b-td :rowspan="attachment.data.length+2">
							<div class="table-row-center" v-if="attachment.scope === 0 || $checkPermission('list-company-all')">
								<div v-if="companyOptions.length >= 1" class="action copy">
									<font-awesome-icon class="mx-2" :icon="['fas', 'clipboard-check']"
										@click="showCopyModal(attachment)"/>
								</div>
							</div>
						</b-td>
					</b-tr>
					<b-tr v-for="(a, i) in attachment.data" :key="i">
						<b-td>{{a.description}}</b-td>
						<b-td>{{$langs[a.language]}}</b-td>
						<b-td>
							<span>{{ a.scope==0 ? companyData.company.name : $t('Template.Global') }}</span>
						</b-td>
						<b-td>
							<div class="table-row-center">
								<div class="action delete">
									<font-awesome-icon class="mx-2" :icon="['fas', 'download']"
										@click="downloadAttachment(a)"/>
								</div>
								<div class="action edit" v-if="a.scope === 0 || $checkPermission('list-company-all')">
									<font-awesome-icon class="mx-2" :icon="['fas', 'pencil-alt']"
										@click="editAttachment(a)"/>
								</div>
								<div class="action delete" v-if="$checkPermission('list-company-all')">
									<font-awesome-icon class="mx-2" :icon="['fas', 'trash-alt']"
										@click="confirmDeleteAttachment(a)"/>
								</div>
							</div>
						</b-td>
					</b-tr>
				</b-tbody>
				<b-tbody v-if="Object.keys(attachmentList).length === 0">
					<b-tr class="empty-row">
						<b-td :colspan="$checkPermission('list-company-all')? 6 : 4" class="text-center">{{ $t('General.TableEmpty') }}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>

	</b-container>
</template>

<script>
import companyService from '../services/company.service';
import syspropService from '../services/sysprop.service';
import templateService from '../services/template.service';
import { TemplateScope } from '../../../server/common/constants';
import Vue from 'vue';

export default {
	data: function() {
		return {
			loading: false,
			searchTimeout: null,
			companyData: null,
			attachmentList: [],
			attachmentCompany: null,
			idOptions: [],
			companyId: localStorage.getItem('cg-company'),
			companyOptions: [],
			alertMessage: '',
			alertVariant: 'success',
			newAttachmentFields: {
				name: 'new-attachment',
				title: 'Attachment.NewAttachment',
				label: 'Attachment.NewAttachment',
				item: {},
				submitHandler: "confirmUploadAttachment",
				fields:[
				{
						id: "id",
						label: 'Attachment.ID',
						type: "select",
						display: true,
						placeholder: 'Attachment.ID',
						validator: this.enforceCompany
					},
					{ 
						id: "language",
						label: 'Attachment.Language',
						type: "select",
						required: true,
						display: true,
						placeholder: 'Attachment.Language',
					},
					{
						id: "name",
						label: 'Attachment.Name',
						type: "text",
						required: true,
						display: true,
						placeholder: 'Attachment.Name',
					},
					{ 
						id: "description",
						label: 'Attachment.Description',
						type: "text",
						required: true,
						display: true,
						placeholder: 'Attachment.Description',
					},
					{   
						id: "company_id",
						label: 'LandingPage.Company',
						type: "select",
						display: true,
						placeholder: 'LandingPage.Company'
					},
					{ 
						id: "file",
						label: 'Attachment.File',
						type: "file",
						display: true,
						placeholder: 'Attachment.SelectFile',
						acceptFileType: "application/*"
					}
				]
			},
			fields: [],
			attachmentScopeFilter: null,
			attachmentScopeOptions: []
		};
	},
	methods: {
		async getAttachments() {
			this.loading = true;

			try {
				let res = await templateService.getTemplateAttachments(this.companyId, this.attachmentScopeFilter);
				console.debug("Attachment - getAttachments success", res);

				this.attachmentList = res.data.reduce((acc, obj) => {
					if (!acc[obj.id]) {
						acc[obj.id] = { id: obj.id, scope: obj.scope, data: [] };
					}
					acc[obj.id].data.push(obj);
					return acc;
				}, {});

				const uniqueIds = [...new Set(res.data.map(obj => obj.id))];
				this.idOptions = uniqueIds;
				this.idOptions.unshift({ value: null, text: this.$i18n.t('Attachment.UploadNewDoc') });
				Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'id'), 'options', this.idOptions);
			} catch (error) { 
				console.error("Attachment - getAttachments error", error);

				this.alertMessage = 'Attachment.RetrieveError';
				this.alertVariant = 'danger';
				this.$refs.cgLocAlert.showAlert();
			} finally {
				this.loading = false;
			}
		},
		createNewAttachment() {
			this.newAttachmentFields.title = 'Attachment.NewAttachment';
			this.newAttachmentFields.label = 'Attachment.NewAttachment';

			Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'file'), 'required', true);
			Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'id'), 'disabled', false);
			Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'language'), 'disabled', false);
			Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'company_id'), 'disabled', !this.$checkPermission('list-company-all'));

			this.cleanForm();
			this.$bvModal.show(this.newAttachmentFields.name);
		},
		editAttachment(record) {
			this.newAttachmentFields.title = 'Attachment.EditAttachment';
			this.newAttachmentFields.label = 'General.Save';

			Vue.set(this.newAttachmentFields, 'item', { ...record, isEdit: true });
			Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'file'), 'required', false);
			Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'id'), 'disabled', true);
			Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'language'), 'disabled', true);
			Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'company_id'), 'disabled', true);

			this.$bvModal.show(this.newAttachmentFields.name);
		},
		enforceCompany(value) {
			if(value) {
				let company_id = this.attachmentList[value].data[0].company_id;
				Vue.set(this.newAttachmentFields.item, 'company_id', company_id);
				Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'company_id'), 'disabled', true);
			} else {
				Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'company_id'), 'disabled', false);
			}

			return true;
		},
		showCopyModal(record) {
			this.setCompanyOptions(record.data[0]);

			this.currentAttachment = record.data[0].id;
			this.$bvModal.show('copyAttachmentModal');
		},
		async copyTemplateAttachment() {
			this.inProgress = true;

			try {
				await templateService.copyAttachment(this.currentAttachment, { companyId: this.attachmentCompany });
				this.alertVariant = 'success';
				this.alertMessage = 'Attachment.CopySuccess';
			} catch (error) {
				this.alertVariant = 'danger';
				this.alertMessage = 'Attachment.CopyError';
			} finally {
				this.$refs.cgLocAlert.showAlert();
				this.$bvModal.hide('copyAttachmentModal');

				this.inProgress = false;
				this.$nextTick(() => {
					if(this.alertVariant === 'success') {
						this.getAttachments();
					}
				});	
			}
		},
		async downloadAttachment(attachment) {
			const id = attachment.id;
			const language = attachment.language;
			const type = attachment.type;
			const name = attachment.name;

			console.debug("Downloading attachment", id, language, name, type);

			this.loading = true;

			try {
				const response = await fetch(`/api/template/attachments/content?id=${id}&language=${language}`);
				if (!response.ok) {
					throw new Error('Download failed');
				}
				
				const blob = await response.blob();
				const url = URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = "Attachment_" + id + "_" + language;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			} catch (err) {
				console.error("Attachment - downloadTemplateAttachment error", err);

				this.alertMessage = 'Attachment.DownloadError';
				this.alertVariant = 'danger';
				this.$refs.cgLocAlert.showAlert();
			} finally {
				this.loading = false;
			}
		},
		cleanForm() {
			this.newAttachmentFields.item = {
				id: null,
				name: null,
				description: null,
				language: this.$i18n.locale,
				file: null,
				company_id: this.$checkPermission('list-company-all')? null : this.companyId
			}
		},
		confirmUploadAttachment(attachment) {
			if(attachment.isEdit) {
				console.debug("Attachment is being edited, of course it exists already on the database and it's going to be overridden");
				this.addNewAttachment(attachment);
				return;
			}

			const id = attachment.id;
			const language = attachment.language;
			const existFile = this.attachmentList[id] && this.attachmentList[id].data.filter(el => el.language === language).length > 0;

			if(existFile) {
				console.debug("Attachment language for this ID already exists, ask permission to override");
				this.overrideAttachment(attachment);
			} else {
				console.debug("A brand new Attachment is going to be uploaded");
				this.addNewAttachment(attachment);
			}
		},
		overrideAttachment(attachment) {
			// This function displays a modal for the user to confirm the override
			this.$bvModal.msgBoxConfirm(this.$t("Attachment.ConfirmAttachmentOverrideMessage"), {
				title: this.$t("Attachment.AttachmentOverrideMessage"),
				size: 'md',
				buttonSize: 'md',
				okVariant: 'danger',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				// If the user confirms the override, value is not null
				if (value) {
					attachment.isEdit = true;
					this.addNewAttachment(attachment);
				}
			}).catch(err => {
				// The user canceled the action
				console.error("Attachment - confirmOverrideAttachment error", err);
			});
		},
		async addNewAttachment(newAttachment) {
			this.loading = true;

			let attachmentData = new FormData();
			attachmentData.append('attachment', newAttachment.file);
			attachmentData.append('name', newAttachment.name);
			attachmentData.append('description', newAttachment.description);
			attachmentData.append('language', newAttachment.language);
			attachmentData.append('id', newAttachment.id);
			if(newAttachment.company_id) attachmentData.append('companyId', newAttachment.company_id);

			let isEdit = newAttachment.isEdit;

			try {
				let res = await templateService.updateTemplateAttachment(attachmentData);
				console.debug("Attachment - manageAttachment success", res);

				this.alertMessage = isEdit ? 'Attachment.EditSuccess' : 'Attachment.CreateSuccess';
				this.alertVariant = 'success';

				this.getAttachments();
			} catch (error) { 
				console.error("Attachment - manageAttachment error", error);

				this.alertMessage = isEdit ? 'Attachment.EditError' : 'Attachment.CreateError';
				this.alertVariant = 'danger';
			} finally {
				this.loading = false;
				this.$refs.cgLocAlert.showAlert();
			}
		},
		async setCompanyOptions(attachment) {
			this.companyOptions = [];

			// Exclude current company from list
			let filteredCompanyList = this.companyList.filter((c) => { return c.company_id !== attachment.company_id; });

			this.attachmentCompany = filteredCompanyList.length? filteredCompanyList[0].company_id : null;

			// Check if the logged user is admin
			if (this.$checkPermission('list-company-all') && (attachment.company_id)) {
				this.attachmentCompany = null;
				this.companyOptions.push( { value: null, text: this.$t('Template.Global') } );	
			}

			filteredCompanyList.forEach(c => {
				this.companyOptions.push( { value: c.company_id, text: c.name } );
			});

			Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'company_id'), 'options', this.companyOptions);
		},
		confirmDeleteAttachment(attachment) {
			this.$bvModal.msgBoxConfirm(this.$i18n.t("Attachment.ConfirmAttachmentDeleteMessage"), {
				title: this.$i18n.t("Attachment.AttachmentOverrideTitle"),
				size: 'md',
				buttonSize: 'md',
				okVariant: 'danger',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(value => {
				if (value) {
					this.deleteAttachment(attachment);
				}
			}).catch(err => {
				console.error("Attachment - confirmDeleteAttachment error", err);
			});
		},
		async deleteAttachment(attachment) {
			this.inProgress = true;
			templateService.deleteAttachment(attachment.id, attachment.language).then(() => {
				console.debug("Attachment - deleteAttachment success");
				this.alertVariant = 'success';
				this.alertMessage = 'Attachment.AttachmentDeleted';
				this.$nextTick(() => {
					this.getAttachments();
				});
			}).catch((error) => {
				console.error("Attachment - deleteAttachment error", error);
				this.alertVariant = 'danger';
				this.alertMessage = 'Attachment.AttachmentDeleteError';
			}).finally(() => {
				this.inProgress = false;
				this.$refs.cgLocAlert.showAlert();
			});
		}
	},
	async created() {
		const config = await syspropService.getConfig();
		this.companyData = { contact: config.data.defaults.contact, gp_landing_url: config.data.defaults.gp_landing_url };

		if(this.companyId) {
			let companyRes = await companyService.getCompany(this.companyId);
			this.companyData.company = companyRes.data;
		}
		
		this.attachmentScopeOptions = [
			{ value: TemplateScope.GlobalAndCompany, text: this.$t('Template.GlobalAndCompany') },
			{ value: TemplateScope.Global, text: this.$t('Template.Global') },
			{ value: TemplateScope.Company, text: this.$t('Template.Company') }
		];

		if (this.$checkPermission('list-company-all')) {
			this.attachmentScopeFilter = TemplateScope.GlobalAndCompany; 
		} else { // Limit the scope to company
			this.attachmentScopeFilter = TemplateScope.Company;
		}

		await this.getAttachments();

		this.fields = [
			{ key: 'id', label: this.$t('Attachment.ID'), sortable: true },
			{ key: 'name', label: this.$t('Attachment.Name'), sortable: true, class: "text-ellipsis" },
			{ key: 'description', label: this.$t('Attachment.Description'), sortable: true, class: "text-ellipsis" },
			{ key: 'language', label: this.$t('Attachment.Language'), sortable: true }
		];

		let result = await companyService.getCompanyList();
		this.companyList = result.data.sort((a, b) => { return ('' + a.name).localeCompare('' + b.name); });

		// Check if the logged user is admin
		if (this.$checkPermission('list-company-all')) {
			this.defaultCompanyId = null;
			this.companyOptions.push( { value: null, text: this.$t('Template.Global') } );
		} else {
			this.defaultCompanyId = this.companyId;
		}

		this.companyOptions.push({ value: this.companyId, text: this.companyData.company.name });
		Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'company_id'), 'options', this.companyOptions);

		Vue.set(this.newAttachmentFields.fields.find(i => i.id === 'language'), 'options', this.$langs);
	}
};
</script>


<style lang="less">
#attachmentPage {
    .empty-row {
        line-height: 70px;
	}
}
</style>